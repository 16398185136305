import { TwitchPlayer } from "react-twitch-embed";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import styles from "./style.module.css";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { ViewPort } from "@/components/ViewPort";
import { PlayCircle, Volume, XVolume } from "@/components/Icons";

interface Props {
  view: "twitch" | "countdown" | "link";
  enddate: number;
  link: string;
  channel: string;
}

const CountDownRenderer: CountdownRendererFn = (props) => {
  const { milliseconds } = props;
  const { hours, minutes, seconds } = props.formatted;

  return (
    <div className={styles.countdown__container}>
      <span className={styles.countdown}>
        {hours}:{minutes}:{seconds}:{milliseconds}
      </span>
      <span className={`${styles.countdown} ${styles["countdown--blur"]}`}>
        {hours}:{minutes}:{seconds}:{milliseconds}
      </span>
    </div>
  );
};

const HomeInner = ({ view, enddate, link, channel }: Props) => {
  switch (view) {
    case "twitch":
      return (
        <TwitchPlayer
          channel={channel}
          autoplay
          muted
          width="100%"
          height="100%"
        />
      );
    case "link":
      return (
        <div>
          <span>Thanks for watching</span>
          <br />
          <br />
          <a
            href={link}
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            visit adoption page
          </a>
        </div>
      );
    case "countdown":
      return (
        <Countdown
          date={new Date(enddate * 1000)}
          daysInHours
          zeroPadTime={2}
          renderer={CountDownRenderer}
        />
      );
    default:
      return null;
  }
};

export const Home = () => {
  const [powered, setPowered] = useState(true);
  const [audioPlay, setAudioPlay] = useState(false);
  const query = useQuery({
    queryKey: ["stats"],
    queryFn: async () => {
      const res = await fetch("/api/stats");

      return res.json();
    },
    refetchInterval: 60000, // refetch every 60 seconds
  });

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    setInterval;
  }, []);

  useEffect(() => {
    if (audioPlay && audioRef.current) {
      audioRef.current.play();
    }

    if (!audioPlay && audioRef.current) {
      audioRef.current.pause();
    }
  }, [audioPlay]);

  const handlePlay = () => {
    setPowered(true);
    setAudioPlay(true);
  };

  return (
    <div className={styles.container}>
      <ViewPort />
      <audio
        ref={audioRef}
        src="/ambiant.mp3"
        style={{ display: "none" }}
        loop
      ></audio>

      <button
        className={styles.volume__controls}
        onClick={() => setAudioPlay((currentPlay) => !currentPlay)}
      >
        {audioPlay ? <XVolume /> : <Volume />}
      </button>

      <div className={styles.screen}>
        {!powered ? (
          <div onClick={handlePlay} className={styles.play}>
            <PlayCircle width="48" height="48" />
          </div>
        ) : query.data ? (
          <HomeInner
            {...query.data}
            key={{
              view: query.data.view,
              enddate: query.data.enddate,
              link: query.data.link,
              channel: query.data.channel,
            }.toString()}
          />
        ) : (
          <div>loading</div>
        )}

        <img src="images/control.png" className={styles.overlay} />
      </div>
    </div>
  );
};
