import Head from "next/head";
import { Home as HomeLayout } from "@/layouts/Home";
import localFont from "next/font/local";

const digitalNumbersFont = localFont({ src: "./DigitalNumbers-Regular.ttf" });

export default function Home() {
  return (
    <>
      <Head>
        <title>Pentoshi Control Center</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:title" content="Pentoshi Control Center" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://pentoshicontrol.center" />
        <meta
          property="og:image"
          content="https://pentoshicontrol.center/images/og.png"
        />
        <meta
          property="og:description"
          content="Pentoshi Control Center is monitoring the current status of the space exploration."
        />
      </Head>

      <main className={digitalNumbersFont.className}>
        <HomeLayout />
      </main>
    </>
  );
}
