import React from "react";

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

export const Volume = (props: Props) => {
  return (
    <svg
      className="feather feather-volume"
      height="24"
      width="24"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
    </svg>
  );
};
