import styles from "./style.module.css";

export const ViewPort = () => {
  return (
    <div className={styles.container}>
      <img className={styles.mask} src="/images/mask.png" />
      <div className={styles.scanlines} />
    </div>
  );
};
